export enum WALLETS_NAME {
  Keplr = 'Keplr',
  TerraStation = 'Terra Station',
  Coin98 = 'Coin98',
}

// export const WALLETS_CHAIN_ID: Record<string, string> = {
//   AURA_TESTNET: 'aura-testnet',
//   JUNO_TESTNET: 'uni-3',
//   OSMOSIS_TESTNET: 'osmo-test-4',
//   GAIA_TESTNET: 'theta-testnet-001',
//   TERRA_TESTNET: 'bombay-12',
//   AURA_SERENITY: 'serenity-testnet-001',
//   AURA_HALO: 'halo-testnet-001',
// }
